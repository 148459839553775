<template>
  <div class="container position-relative">
    <!-- begin::No module -->
    <template v-if="!equipment || equipment.isDeviceLinked() === false">
      <no-module-linked-alert
        ref="noModuleLinked"
        class="col-12"
      />
    </template>
    <!-- end::No module -->

    <!-- begin::Maintenance bars -->
    <template v-else>
      <!-- begin::Loading -->
      <template v-if="isMounted === false || isLoading">
        <div class="loading-container loading-overlap">
          <div class="loading-backdrop rounded" />
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span class="float-left">Gathering data...</span>
              <span class="spinner spinner-primary ml-auto" />
            </div>
          </div>
        </div>
      </template>
      <!-- end::Loading -->

      <!-- begin::Error -->
      <div v-else-if="isErrorActive" class="row mt-3 px-5">
        <error-translation-alert
          ref="errorAlert"
          :error="activeError"
          class="col"
        />
      </div>
      <!-- end::Error -->

      <template v-else-if="!maintenanceCounters || maintenanceCounters.length === 0">
        <div class="alert alert-custom alert-light-info mb-0" role="alert">
          <div class="alert-icon">
            <i>
              <font-awesome-icon :icon="['fas', 'tools']" />
            </i>
          </div>
          <div class="alert-text">
            <span class="font-weight-bold d-block">No maintenance information available</span>
            <span class="d-block">
              No current maintenance information is available for this sunbed.
            </span>
          </div>
        </div>
      </template>

      <div
        v-else
        v-for="(counter) in maintenanceCountersMapped"
        :key="`equipment.${equipment._meta.guid}.maintenance.hour.${counter.counter}`"
        class="row mt-3 pb-3 border-bottom"
      >
        <div class="col-12 col-md">
          <div class="overflow-ellipsis mb-0 h6">{{ $tu(`sunbeds.models.${equipment.model}.maintenance.hours.${counter.counter}.name`) || counter.counter }}</div>
          <span class="text-muted">
            <template v-if="counter.maintenance && counter.maintenance.due_date">Maintenance due <span class="text-body">{{ $moment.utc(counter.maintenance.due_date).format('MMMM Do, YYYY') }}</span>, based on average usage.</template>
            <template v-else>No estimated maintenance due date available.</template>
          </span>
        </div>

        <div class="col col-xl-4 mt-3 mt-lg-0 d-flex flex-grow-1 align-items-center justify-content-end">
          <div class="text-right h6 mb-0 min-w-md-80px">
            <span class="value">{{ Math.floor(counter.value / 60) }}</span> / <span class="text-muted">{{ counter.max_value_text ? counter.max_value_text : Math.floor(counter.max_value / 60) }}</span>
          </div>
          <div class="flex-grow-1 pl-5">
            <div class="progress h-10px">
              <div
                :class="['progress-bar', `bg-${counter.variant || 'primary'}`]"
                role="progressbar"
                :style="{ width: `${counter.percentage * 100}%` }"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- end::Maintenance bars -->
  </div>
</template>

<script>
import moment from 'moment-timezone';
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';
import { errorComponentMixin } from '@vedicium/core-vue';
import { EQUIPMENT_HEALTH_INFINITY_NUMBER } from '@/libs/equipment';
import Equipment from '@/libs/classes/equipment';

import errorTranslationAlert from '@/components/errors/translation.alert.vue';
import noModuleLinkedAlert from '@/components/pages/sunbeds/view/components/no-module-linked.alert.vue';

export default {
  mixins: [equipmentMixin, errorComponentMixin],
  components: {
    errorTranslationAlert, // eslint-disable-line
    noModuleLinkedAlert,
  },
  data () {
    return {
      isLoading: false,
      isMounted: false,

      maintenanceCounters: [],
    };
  },
  computed: {
    maintenanceCountersMapped () {
      if (!this.maintenanceCounters) {
        return [];
      }

      const currentDate = moment.utc();
      return this.maintenanceCounters.map((counter) => {
        const object = {
          ...counter,
          percentage: (counter.value / counter.max_value),
          variant: 'success',
        };

        // Set danger variant if it's passed it's max value
        if (counter.value >= counter.max_value) {
          object.variant = 'danger';
        }

        // Set warning variant if maintenance due date is calculated and is within it's window
        if (counter.maintenance && counter.maintenance.due_date && counter.value < counter.max_value) {
          // By default, it's 14 days before maintenance is due
          let maintenanceDueDays = 14;

          // This is changed by the notification settings, if enabled
          if (counter.notification_settings && counter.notification_settings.maintenance_preventative && counter.notification_settings.maintenance_preventative.enabled === true) {
            maintenanceDueDays = counter.notification_settings.maintenance_preventative.days_before_due || maintenanceDueDays;
          }

          // Check if maintenance due date is equal to or less than current date
          // Add 1 so the current date is also added in the difference lookup
          const maintenanceDueDaysDifference = moment.utc(counter.maintenance.due_date).diff(currentDate, 'days') + 1;
          if (maintenanceDueDaysDifference <= maintenanceDueDays) {
            object.variant = 'warning';
          }
        }

        // When the max value is 'EQUIPMENT_HEALTH_INFINITY_NUMBER', set the variant to 'info' & percentage to 100
        if (object.max_value === EQUIPMENT_HEALTH_INFINITY_NUMBER) {
          object.variant = 'info';
          object.percentage = 1;
          object.max_value_text = '∞';
        }

        return object;
      });
    },
  },

  async mounted () {
    this.$set(this, 'isLoading', true);

    try {
      this.$set(this, 'maintenanceCounters', await this.$ws.get(`${Equipment.uri}/${this.equipment._meta.guid}/maintenance/counters`));
      this.$store.set('sunbeds:view:equipment:maintenance', this.maintenanceCounters);
    } catch (e) {
      console.error(e);
      this.$errors.handle(e, { component: this, ui_element: false });
    } finally {
      this.$set(this, 'isLoading', false);
      this.$set(this, 'isMounted', true);
    }
  },
};
</script>
